<template>
  <v-row justify="center">
    <v-dialog
      v-model="LoginModal"
      persistent
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Login</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="email"
                  label="Email*"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                v-model="password"
                  label="Password*"
                  type="password"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
          <Loading v-if="Loading" class="ma-7"/>
        <v-card-actions>
               <v-btn
               small
                color="info"
                text
                @click="Signup()"
                >
            Sign up
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="LoginModaFucl(false)"
          >
            Close
          </v-btn>
          <v-btn
            dark
            color="blue darken-1"
            @click="Login()"
          >
            Login
          </v-btn>
        </v-card-actions>
      <div class="text-center ma-10">
          <p><strong> or via social</strong> </p>
         <v-btn
         @click="loginWithGoogle(i)"
         :key="index"
         v-for="(i, index) in icons"
          class="mx-1"
          dark
          :color="color[index]"
          fab
        >
          <v-icon>
            {{ i }}
          </v-icon>
        </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import Loading from '../components/Loading'
import {GoogleAuth} from '../APIs'
import axios from "axios";
  export default {
      props:['LoginModal', 'LoginModaFucl'],
    data: () => ({
      dialog: false,
      email:"",
      password:"",
        icons: [
        'mdi-facebook',
        'mdi-google',
      ],
       color:[
          '#1c63ba',
          'red'
      ]
    }),
    components:{
        Loading,
      },
        computed:{
        Loading() {
            return this.$store.getters.Loading;
          },
        },
    methods:{
      oauth(){
      this.$store.commit("setSnackbar", true)
      this.$store.commit("setServerMessage", "Not Available")
    },
      async loginWithGoogle(payload){
       if(payload == 'mdi-facebook'){
         this.oauth()
         return
       }
       try{
        const googleUser = await this.$gAuth.signIn()
        let user = googleUser.getAuthResponse()
          this.$store.commit("setLoading", true)
          axios.put(`${GoogleAuth}`,{
            token: user.id_token
          })
          .then(res => {
            this.$store.commit("setUser", res.data.user)
            this.$store.commit("setToken", res.data.token)
          })
           .catch(err => {
            console.log(err.response.data, "GoogleAuth: ERR")
            this.$store.commit("setSnackbar", true);
            this.$store.commit("setServerMessage", `Err: ${err.response.data.message}`)
          })
          .finally(()=>{
             this.$store.commit("setLoading", false)
             this.LoginModaFucl(false)
          }) 
       }
       catch(e){
          console.log(e, 'err')
          this.$store.commit("setLoading", false)
       }
      },
        Signup(){
          this.LoginModaFucl(false)
          this.$router.push({name:"signup"})  
        },
    Login(){
      if(this.password == '' || this.phoneNumber == ''){
        this.$store.commit("setSnackbar", true)
        this.$store.commit("setServerMessage", "Fill All The Required Field")
        return
      }
          let payload= {
            route: this.$router,
            phoneNumber: this.phoneNumber,
            password: this.password,
            redirect: this.$route.query.redirect,
            QuckLogin: true
          }
          this.$store.dispatch('Login', payload)
        }
    }
  }
</script>