<template>
    <paystack
        :amount="this.totalAmount *100"
        :email="this.email ? this.email:'info@mvs.com'"
        :paystackkey="paystackkey"
        :reference="reference"
        :callback="callback"
        :close="close"
        :embed="false"
    >
      <v-btn
          dark
          class="ma-5"
          color="success darken-1"
          rounded 
          >
          Make Payment
          </v-btn>
    </paystack>
</template>
 
<script type="text/javascript">
import paystack from 'vue-paystack';


export default {
    props:["totalAmount","email", "orderId"],
    components: {
        paystack
    },
    data(){
        return{
          paystackkey: "pk_test_d709cbf67c203225c32437bd1cda8bc01b8e7ff2"
        }
    },
    computed: {
      reference(){
        let text = "";
        let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
 
        for( let i=0; i < 10; i++ )
          text += possible.charAt(Math.floor(Math.random() * possible.length));
 
        return text;
      },
    Token() {
      return this.$store.getters.Token;
    },
    },
    methods: {
      callback(response){
        this.$store.commit("setLoading", true);
        console.log(response, "Make payment")
        this.VerifyPayment(response)
      },
      VerifyPayment(response){
         let payload={
          orderId:this.orderId,
          reference: response.reference,
          Token:this.Token,
          route: this.$router,
        }
        this.$store.dispatch('VerifyPay', payload)

      },
      close(){
          console.log("Payment closed")
      }
    }
}
</script>