<template>
   <v-container class="C1">
  <div class="main">
  <div class="DeliveryForm ma-5">
      <div class="deliveryInfo pa-10">
       <p class="display-1 font-weight-thin">Delivery Information</p>
      </div>
    <v-form>
    <v-container>
       <vuetify-google-autocomplete
           :disabled="disabled"
            ref="address"
            id="map"
            classname="form-control"
            placeholder="Enter Delivery Address"
            v-on:placechanged="getAddressData"
            country="ng"
              shaped
              filled
               background-color='#e7f6ff'
        >
        </vuetify-google-autocomplete>
          <v-text-field
            :disabled="disabled"
            v-model="contactName"
            shaped
            background-color='#e7f6ff'
            label="Contact Person Name"
            filled
          ></v-text-field>
          <v-text-field
         :disabled="disabled"
             v-model="contactPerson"
            shaped
            type='Number'
            background-color='#e7f6ff'
            label="Contact Person Phone Number"
            filled
          ></v-text-field>
    </v-container>
  </v-form>
  </div>
  <div class="cartDetail ma-5">
        <div class="subtotal">
           <CartDetails/>
         </div>
    <v-divider class="mt-3"></v-divider>
    <div class="subtota">
     <div class="sub">
         <strong class="color">Subtotal</strong>
         <v-spacer></v-spacer>
         <strong>{{DisplayCurrency(totalAmount - parseFloat(shippingFee) )}}</strong>
     </div>
       <div class="sub">
         <strong class="color">Delivery Fee</strong>
         <v-spacer></v-spacer>
         <span v-if="!shippingFee">CALCULATED AT NEXT STEP</span>
         <span v-else>{{DisplayCurrency(shippingFee)}}</span>
     </div>
       <div v-if="distance" class="sub">
         <strong class="color">Distance</strong>
         <v-spacer></v-spacer>
         <span>{{distance}}</span>
     </div>
    <v-divider class="mt-3"></v-divider>
      <div class="sub">
         <strong>TOTAL</strong>
         <v-spacer></v-spacer>
         <strong>{{DisplayCurrency(totalAmount)}}</strong>
     </div>
         <div class="text-center">
    <v-btn
    v-if="disabled==false || Loading != false"
    @click="placeOrder()"
    class="pl-10 pr-10 pt-5 pb-5"
      color="success"
      dark
    >
       CALCULATE Shipment
    </v-btn>
    <MakePayment v-if="disabled == true" :orderId="orderId" :totalAmount="totalAmount" :email="email" />
    <Loading v-if="Loading" class="ma-7"/>
  </div>
    </div>
  </div>
  <LoginModal :LoginModaFucl="LoginModaFucl" :LoginModal="LoginModal" />
  </div>
   </v-container>
</template>

<script>
import currencyFormatter from 'currency-formatter'
import {PlaceOrder, DomainImage} from '../APIs'
import axios from "axios";
import Loading from '../components/Loading'
import LoginModal from '../components/LoginModal'
import MakePayment from '../components/MakePayment'
import CartDetails from '../components/CartDetails'

export default {
  created(){
      this.DomainImage = DomainImage
      this.contactName = this.User.fullname
      this.contactPerson = this.User.phoneNumber
  },
    data: () => ({
      LoginModal:false,
      contactPerson:"",
      deliveryLocation:"",
      contactName:"",
      shippingFee:0,
      distance:"",
      disabled:false,
      email:"",
      orderId:"",
      DomainImage:""

    }),
      components:{
        Loading,
        MakePayment,
        CartDetails,
        LoginModal
      },
  methods:{
    LoginModaFucl(payload){
      this.LoginModal = payload
    },
    placeOrder(){
      if(!this.Token){
       this.$store.commit("setSnackbar", true)
        this.$store.commit("setServerMessage", "Please Login To Continue")
        this.LoginModaFucl(true)
        return
      }
      if(Date.now() > parseInt(this.LoginTime)){
       this.$store.commit("setSnackbar", true)
        this.$store.commit("setServerMessage", "Please Login To Continue")
         let payload ={
            route: this.$router,
        }
        this.$store.commit("setSignOut", payload)
        return
      }
       if(this.contactPerson == '' || this.contactName == '' || this.deliveryLocation == ''){
        this.$store.commit("setSnackbar", true)
        this.$store.commit("setServerMessage", "Fill All The Required Field")
        return
      }
      let orderDetails=[]
      this.Carts.forEach(element=>{
        let payload ={
          id:element._id,
          quantity:element.quantity,
          name:element.name
        }
        orderDetails.push(payload)
      })
      this.$store.commit("setLoading", true);
      axios.post(`${PlaceOrder}`,{
        storeId: this.Store._id,
        deliveryLocation:this.deliveryLocation,
        contactPerson:{contactPerson: this.contactPerson, contactName:this.contactName},
        orderDetails:orderDetails
      },
      {
         headers: { Authorization: "Bearer " + this.Token } 
      })
          .then(res => {
              console.log(res.data, "res.data")
              this.shippingFee = res.data.order.shippingFee
              this.distance = res.data.distance
              this.disabled = true
              this.orderId = res.data.order._id
          })
          .catch(err => {
            this.disabled = false
            console.log(err.response, "o: ERR")
            this.$store.commit("setSnackbar", true);
            this.$store.commit("setServerMessage", err.response.data.message)
          })
          .finally(()=>{
             this.$store.commit("setLoading", false);
          })

    },
      DisplayCurrency(payload) {
       return currencyFormatter.format(payload, {
        code: this.Store.currency
    });
    },
    getAddressData(payload){
      this.deliveryLocation = payload
      console.log(payload)
    }
  },

  computed:{
    Loading() {
        return this.$store.getters.Loading;
      },
  page(){
       return this.$route.name
  },
  User() {
      return this.$store.getters.User;
  },
  Store() {
      return this.$store.getters.Store;
  },
  Carts() {
      return this.$store.getters.totalItem;
  },
  Token() {
      return this.$store.getters.Token;
  },
   LoginTime() {
      return this.$store.getters.LoginTime;
  },
     totalAmount(){
      let amount = 0
      this.Carts.forEach(element => {
      amount = parseFloat(amount) + parseFloat(element.SellingPrice) * parseFloat(element.quantity)   
      });
      return amount + parseFloat(this.shippingFee)
    },
  },
   watch: {
      Token (val) {
      if(val){
        this.LoginModaFucl(false)
      }
    },
    User(){
      this.contactName = this.User.fullname
      this.contactPerson = this.User.phoneNumber
    }
  },

}
</script>

<style scoped>

.main{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 0px;
  grid-template-areas:
    "DeliveryForm DeliveryForm cartDetail cartDetail"
}

.subtotal{
    margin: 30px;
    max-height: 50vh;
    overflow: scroll;
}
.subtotal::-webkit-scrollbar {
  width: 0.4em !important;
  
}
.subtotal::-webkit-scrollbar-thumb {
  background-color: #1c63ba!important;
  border-radius: 10px!important;
}
.DeliveryForm{
   grid-area: DeliveryForm;
    background-color: #fff;
    
}
.cartDetail{
   grid-area: cartDetail;
   overflow: scroll;
   background-color: #e7f6ff;
}
.cartDetail::-webkit-scrollbar {
  width: 0.4em !important;
  
}
.cartDetail::-webkit-scrollbar-thumb {
  background-color: #1c63ba!important;
  border-radius: 10px!important;
}
.cartImg{
  border-radius: 5px;
  width: 127px;
}
.C1{
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 80vh;
}
.description{
  font-family: Poppins;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.71;
  letter-spacing: normal;
  text-align: left;
  color: #374146;
}
.amount{
  width: 77px;
  height: 27px;
  margin: 53px 0 41px 56px;
  font-family: Poppins;
  font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: #1156aa;
}
.sub{
    display: flex;
    margin: 10px;
    margin-left: 30px;
    margin-right: 30px;
}
.color{
 color: #1156aa;
}
.qty{
  text-align: center;
  font-family: Poppins;
  font-size: 10px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: #1156aa;
}
.qtyform{
  display: flex;
  justify-content: center;
  align-items: center;
}
.card{
    display: flex;
    background-color: #c9ebff;
    height: 120px;
   align-items: center;
   border-radius: 10px;
   padding:10px;
   margin: 10px;
}
.card1{
  flex: 1;
}
.card2{
flex: 2;
margin: 10px;
background-color: red;
}
.card3{
 flex: 1;
}
@media only screen and (max-width: 700px) {
 .main{
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  padding: 0px;
  grid-template-areas:
    "DeliveryForm"
    "cartDetail"
}
.cartDetail{
  width: 100%;
}
.subtotal{
  margin: 5px;
}
}
</style>